
body {
    /* CSS for a gradient background-color */
    /*background: linear-gradient(180deg, paleturquoise 0%, var(--li-white) 8%, var(--li-white) 95%, paleturquoise 100%);*/
    /*background: linear-gradient(90deg, lightcyan 10%, var(--li-white) 30%, var(--li-white) 95%); !* , lightseagreen 100%);*!*/
    /*background: linear-gradient(180deg, lightsteelblue 0%, var(--li-white) 10%, var(--li-white) 90%, lightsteelblue 100%);*/
}

.li-page-header-bg {
    /*background: linear-gradient(180deg, steelblue 0%, var(--li-white) 100%);*/
    background-color: lightslategray; /*var(--li-main-color-light);*/
    color: var(--li-white); 
    padding: 0.5em;
    margin-left: 0 !important;
    border-radius: 5px;
}

.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}


/*#region MEDIA_CHANGING_ITEMS */
@media all and (min-width: 500px) { /* bootstrap xs */
    .modal-dialog {
        max-width: 490px;
    }
}
@media all and (min-width: 768px) { /* bootstrap sm */
    .modal-dialog {
        max-width: 600px;
    }
}
@media all and (min-width: 992px) { /* bootstrap md */
    .modal-dialog {
        max-width: 800px;
    }
}
@media all and (min-width: 1200px) { /* bootstrap lg */
    .modal-dialog {
        max-width: 800px;
    }
}
/*#endregion*/

.li-modal-content {
    /*max-width: 800px;*/
}

.li-header-row {
    background-color: var(--li-bg-light);
    border-top: 2px solid var(--li-dark);
    border-bottom: 2px solid var(--li-dark);
    border-radius: 7px;
    margin-bottom: 10px !important;
}

/* ADMIN */
.li-list-group-w-hover .list-group-item:hover {
    background-color: #eeeeee;
}

/*#region SEARCH PAGE*/

.li-search-field {
    border-color: var(--li-muted);
    padding: 0;
    border-radius: 5px;
    color: var(--li-text);
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 20em;
    max-width: 20em;
}
.li-search-field.small {
    height: 40px;
}
.li-search-field.left {
    margin-right: auto;
    margin-left: 0;
}
.li-search-field.right {
    margin-left: auto;
    margin-right: 0;
}
.li-search-field input {
    line-height: 1.2em;
    font-size: 1.2em;
    padding-left: 10px;
}
#searchBTN {
    /*padding-left:25px;*/
    /*padding-right:25px;*/
}

.li-search-results-table {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    min-width: 100%;
    min-height: 500px;
    padding: 0;
}

.li-next-button, .li-prev-button {
    margin: 10px 10px 10px 10px;
    padding: 10px;
    width: 5em;
    height: 3em;
}

.card-body {
    padding: 0.5rem !important;
}

.li-sample-card {
    text-align: left;
    margin: 5px;
    width: 500px;
    min-width:500px;
    max-width:500px;
    background-color: var(--li-main-color-light) !important;
    cursor: pointer;
    
}
.li-sample-card:hover {
    background-color: var(--li-main-color) !important;
}
.li-sample-card img {
    width: 150px;
    border-radius: 5px;
}
.li-sample-card .small img  {
    width: 50px;
}
.li-sample-card .medium img  {
    width: 100px;
}
.li-sample-card.light {
    background-color: #eeeeee !important;
}
.li-sample-card.dark {
    background-color: transparent !important;
}
.li-sample-card.clear {
    background-color: transparent !important;
    cursor: auto;
}

.li-bg-pale-blue {
    background-color: #e0e6ff;
}
.li-bg-warn-light {
    background-color: #fce450;
}

.li-fg-warn-light {
    color: var(--li-warn-light) !important;
}

.li-border-round-slightly {
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 5px;
    /*border: solid var(--li-light-grey) 1px;*/
    background-color: white;
}

.li-case-card {
    /*text-align: left;*/
    margin: 5px;
    /*width: 100%;*/
    /*min-width:500px;*/
    background-color: var(--li-main-color-light) !important;
    cursor: pointer;
}
.li-case-card:hover {
    background-color: var(--li-main-color) !important;
}

.li-needs-attention-card {
    text-align: left;
    width: 100%;
    min-width:500px;
    max-width:500px;
    background-color: var(--li-main-color-light) !important;
    cursor: pointer;
}
.li-needs-attention-card:hover {
    background-color: var(--li-main-color) !important;
}

/*#endregion SEARCH PAGE*/

/*#region CONTACTS */

.li-contact-search-item {
    cursor: pointer;
}
.li-contact-search-item:hover {
    background-color: var(--li-main-color) !important;
}
.li-search-button {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--li-white);
    background-color: var(--li-main-color);
    padding: 0 5px;
    border-radius: 4px;
    border-color: transparent;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
    vertical-align: center;
    height: unset;
    align-self: unset;
}

.react-date-picker__wrapper {
    border: none !important;
}

/*#endregion CONTACTS */

/*#region SAMPLE_DETAILS_PAGE */

.li-sample-details-image {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    border: 8px black solid;
}

/*#endregion SAMPLE_DETAILS_PAGE */

/*#region CASE_INVENTORY_PAGE */

.li-scrollable-at-250 {
    min-height: 100px;
    max-height: 250px;
    overflow-x: visible;
    overflow-y: auto;
}

.li-scrollable-at-350 {
    min-height: 100px;
    max-height: 350px;
    overflow-x: visible;
    overflow-y: auto;
}

.li-scrollable-at-500 {
    max-height: 500px;
    overflow-x: visible;
    overflow-y: auto;
}

.li-scrollable-at-750 {
    max-height: 750px;
    overflow-x: visible;
    overflow-y: auto;
}

/*#endregion CASE_INVENTORY_PAGE */

/*#region RETRIEVALS */

.li-slideblock-list {
    background-color: var(--li-main-color-light);
    padding: 20px;
    border: var(--li-main-color) 1px solid;
    border-radius: 5px;
}

/*#endregion RETRIEVALS */

/*#region PICKSHEET */

.li-picksheet-table td {
    padding: 0.1rem !important;
}

.li-font-med-small {
    font-size: 12pt;
}

/*#endregion PICKSHEET */

/*#region CHASE_LIST*/

#chaseList .card-body {
    padding: 5px;
}

/*#endregion CHASE_LIST*/

/*#region PROFILES*/

.li-profile-card {
    /*width: 300px;*/
    height: 70px;
}
.li-profile-card .image-missing {
    width: 100px;
    border: dashed 1px black;
    height: 70px;
}
.profileImageInList {
    max-height: 70px;
    margin:3px;
}

/*#endregion PROFILES */

/*#region MAGNIFIER */

.li-magnifier {
    display: none;
    position: absolute;
    z-index:1000;
    width: 250px;
    background-color: var(--li-main-color);
    color: var(--li-white);
    border-radius: 10px;
    border: solid black 3px;
    /*border: none;*/
}
.li-magnifier.li-magnifier-sample-edit {
    width: 350px;
    height: 350px;
    border-radius: 50px;
    pointer-events: none;
    cursor: none;
    background-color: black;

}
.li-magnifier.show {
    display:block;
}
.li-magnifier.hide {
    display:none;
}

#magnifierImg {
    width:244px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/*#endregion MAGNIFIER*/

.remove-w-100 {
    width: auto;
}

/* validation popup */
.li-validation.overlay {
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
}
.li-validation.overlay-on {
    visibility: visible;
    opacity: 1;
}

.li-validation.popup {
    z-index: 12000;
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 400px;
    position: relative;
    transition: all 500ms ease-in-out;
    border: solid black 2px;
    opacity: 1;
}

.li-num-unread-badge {
    position: absolute;
    top: -5px;
    left: -9px;
    background: var(--li-danger);
}

.li-notification-icon {
    border-radius: 5px;
    border: none;
    color: var(--li-white);
    background-color: var(--li-warn-light) !important;
}

.li-notification-card {
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 80px;
}

.nav-link.active {
    background-color: var(--li-main-color) !important;
    color: var(--li-white) !important;
}

/*#region CONSUMABLES */
.li-consumable-digits {
    font-size: 24px;
}

#consumablesAdjustForm input {
    font-size: 24px;
}

#consumablesTable {
    font-size: 24px;
}
/*#endregion CONSUMABLES */

