:root {
    --li-background: #ffffff;
    --li-black: #000000;
    --li-white: #ffffff;
    --li-text: #333333;
    --li-light-grey: #626262;
    --li-light-light-grey: #626262aa;
    --li-light-border: rgba(0,0,0,0.05);

    --li-dark: #626262aa;

    --li-main-color-light: #e4ecf9;
    --li-main-color: #7ea1ea;
    --li-main-color-active: #4779ea;
    --li-main-color-disabled: #626262;

    --li-login-bg-color: #ffffff;
    --li-main-bg-color: #ffffff;
    
    --li-bg-light: #eeeeee;
    
    --li-second-color-disabled: #626262;
    --li-second-color-active: #f02929;
    --li-second-color: #ff6659; /*#f85151;*/
    
    --li-muted: #999898;
    --li-slightly-muted: #777777;
    --li-success: #00bc00;
    --li-success-dark: #007c00;
    --li-danger: #ff6659;
    --li-warn: darkorange;
    --li-warn-light: orange;
    --li-admin: darkviolet;
    
    --li-tiny: 8pt;
    --li-small: 11pt;
    --li-medium: 15pt;
    --li-large: 24pt;
    --li-huge: 32pt;
}

/*#region MEDIA_CHANGING_ITEMS */
@media all and (min-width: 500px) { /* bootstrap xs */
    .li-font-huge-resizing {font-size: 16pt;}
    .li-font-large-resizing {font-size: 12pt;}
    .li-font-medium-resizing {font-size: 11pt;}
    .li-font-small-resizing {font-size: 10pt;}
}
@media all and (min-width: 768px) { /* bootstrap sm */
    .li-font-huge-resizing {font-size: 24pt;}
    .li-font-large-resizing {font-size: 18pt;}
    .li-font-medium-resizing {font-size: 16pt;}
    .li-font-small-resizing {font-size: 14pt;}
}
@media all and (min-width: 992px) { /* bootstrap md */
    .li-font-huge-resizing {font-size: 28pt;}
    .li-font-large-resizing {font-size: 20pt;}
    .li-font-medium-resizing {font-size: 18pt;}
    .li-font-small-resizing {font-size: 16pt;}
}
@media all and (min-width: 1200px) { /* bootstrap lg */
    .li-font-huge-resizing {font-size: 32pt;}
    .li-font-large-resizing {font-size: 24pt;}
    .li-font-medium-resizing {font-size: 20pt;}
    .li-font-small-resizing {font-size: 18pt;}
}
/*#endregion*/

/*#region BUILTIN TAG OVERRIDES */
body {
    margin: 0;
    font-family: 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--li-background);
    color: var(--li-text);
    min-width: 500px !important;
    min-height: calc(100vh - 20px);
}

input[type=checkbox] {
    transform: scale(1.5);
}

input[type=checkbox].large {
    transform: scale(2.5);
}

input:focus-visible.valid {
    color: mediumvioletred;
}

input:focus-visible.invalid {
    color: orange;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    transform: scale(1.5);
}

input[type=radio] {
    transform: scale(1.5);
}

input[type=radio].large {
    transform: scale(2.5);
}

/*#endregion*/

/*#region FG_AND_BG_COLORS */
.li-fg {
    color: var(--li-text) !important;
}
.li-fg-primary {
    color: var(--li-main-color) !important;
}
.li-fg-blue {
    color: blue !important;
}
.li-fg-white {
    color: var(--li-white) !important;
}
.li-fg-black {
    color: var(--li-black) !important;
}
.li-fg-dark {
    color: var(--li-dark) !important;
}
.li-fg-success {
    color: var(--li-success) !important;
}
.li-fg-green {
    color: green !important;
}
.li-fg-danger {
    color: var(--li-danger) !important;
}
.li-fg-warn {
    color: yellow !important;
}
.li-fg-muted {
    color: var(--li-muted);
}
.li-fg-slightly-muted {
    color: var(--li-slightly-muted) !important;
}
.li-bg-white {
    background-color: var(--li-white) !important;
}
.li-bg-blue {
    background-color: blue !important;
}
.li-bg-light {
    background-color: var(--li-bg-light) !important;
}
.li-bg-dark {
    background-color: var(--li-dark) !important;
}
.li-bg-black {
    background-color: var(--li-black) !important;
}
.li-bg-success {
    background-color: green !important;
}
.li-bg-danger {
    background-color: var(--li-danger) !important;
}
.li-bg-warn {
    background-color: var(--li-warn) !important;
}
.li-bg-card {
    background-color: #eeeeee;
}
.li-bg-blue-card {
    background-color: var(--li-main-color-light);
}
.li-bg-warn-light {
    background-color: var(--li-warn-light);
}
.li-bg-pale-warn {
    background-color: lightyellow;
}
.li-bg-pale-ok {
    background-color: rgba(144, 238, 144, 0.1);
}
.li-bg-success {
    background-color: var(--li-success);
}
.li-bg-primary {
    background-color: var(--li-main-color) !important;
}
.li-bg-muted {
    background-color: var(--li-muted);
}
.li-bg-admin {
    background-color: var(--li-admin);
}
.li-bg-duplicate {
    background-color: var(--li-warn-light);
}
.li-bg-picked {
    background-color: var(--li-light-grey);
}
.li-bg-missing-profile {
    background-color: #dc6db3;
}
/*#endregion*/

/*#region FONTS */
.li-font-tiny {
    font-size: var(--li-tiny) !important;
}

.li-font-small {
    font-size: var(--li-small) !important;
}

.li-font-medium {
    font-size: var(--li-medium) !important;
}

.li-font-large {
    font-size: var(--li-large) !important;
}

.li-font-huge {
    font-size: var(--li-huge);
}

.li-font {
    font-family: 'Helvetica Neue', sans-serif;
}
/*#endregion*/

/*#region BOOTSTRAP OVERRIDES */
.badge-white {
    color: #212529;
    background-color: #ffffff;
    border: solid black 1px;
}

.badge-muted {
    color: var(--li-white);
    background-color: var(--li-muted);
    border: solid black 1px;
}

.badge-slide {
    color: var(--li-white);
    background-color: var(--li-main-color);
    border: solid black 1px;
}

.badge-block {
    color: var(--li-white);
    background-color: blue;
    border: solid black 1px;
}
.w-30 {
    width: 30% !important;
}
.w-60 {
    width: 60% !important;
}

/*#endregion BOOTSTRAP OVERRIDES*/

/*#region SECTION MARKERS */

.li-position-marker-section-a {
    background-color: yellow;
}
.li-position-marker-section-b {
    background-color: var(--li-success);
}
.li-position-marker-section-c {
    background-color: violet;
}
.li-position-marker-section-d {
    background-color: cyan;
}

/*#endregion SECTION MARKERS */

/*#region BORDERS */

.li-border-dark {
    border: var(--li-black) solid 1px !important;
   /* outline-color: var(--li-danger) !important;*/

}
.li-border-dark-thick {
    border: var(--li-black) solid 5px !important;

}
.li-border-muted {
    border: var(--li-muted) solid 1px !important;
    
}
.li-border-danger, .li-border-danger:after {
    /*content: "bad";*/
    border-color: var(--li-danger) !important;
    border-width: 3px !important;
    outline-color: var(--li-danger) !important;
    
}
.li-border-good, .li-border-good:after {
    /*content: "good";*/
    border-style: solid;
    border-color: var(--li-success) !important;
    border-width: 3px !important;
    outline-color: var(--li-success) !important;
    border-radius: 5px;
}
.li-border-transparent, .li-border-transparent:after {
    /*content: "good";*/
    border-style: solid;
    border-color: transparent !important;
    border-width: 3px !important;
    outline-color: transparent !important;
    border-radius: 5px;
}
.li-border-left-danger {
    border-left-style: solid;
    border-left-color: var(--li-danger) !important;
    border-left-width: 3px !important;
    outline-color: var(--li-danger) !important;
}
.li-border-left-light {
    border-left-style: solid;
    border-left-color: var(--li-light-border) !important;
    border-left-width: 2px !important;
    outline-color: var(--li-light-border) !important;
}
.li-border-right-light {
    border-right-style: solid;
    border-right-color: var(--li-light-border) !important;
    border-right-width: 2px !important;
    outline-color: var(--li-light-border) !important;
}
.li-border-line {
    border-bottom: var(--li-black) 2px solid;
}
.li-border-line-light {
    border-bottom: var(--li-muted) 1px solid;
}
.li-border-dark-top {
    margin-top: 1em;
    padding-top: 0.25em;
    border-top: var(--li-black) 2px solid;
}
.li-border-light-top {
    margin-top: 1em;
    padding-top: 0.25em;
    border-top: var(--li-muted) 2px solid;
}
.li-show-border {
    border: dashed 2px black;
}
.li-border-round {
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 5px;
    /*border: solid var(--li-light-grey) 1px;*/
    background-color: white;
}


/*#endregion BORDERS */

/*#region FORM_FIELDS */
.li-form-label {
    font-weight: normal;
    font-size: var(--li-medium);
    margin-top: 1em;
    margin-bottom: 0;
}
.li-form-field {
    height: 35px;
    width: 100%;
    /*line-height: 46px;*/
    border-radius: 6px;
    border: 1px solid var(--li-light-border);
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    color: var(--li-black);
    /*background-color: var(--li-light-border);*/
}
.li-form-field.no-height {
    height:unset;
}
.li-form-field.no-width {
    width:unset;
}

.li-form-field:active {
    border-color: var(--li-light-grey);
}

/*#endregion FORM_FIELDS */

/*#region CARDS*/

.li-card {
    padding: 20px;
    border-radius: 6px;
}

.li-card-scroll {
    overflow: auto;
}

/*#endregion CARDS */


/*#region BUTTONS */

.li-dash-button {
    cursor: pointer;
    /*min-width: 200px;*/
    /*max-width: 200px;*/
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--li-white);
    font-size: var(--li-large);
    padding: 30px;
    line-height: 30px;
    border-radius: 10px;
}

.li-button {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    padding: 0 1.5em;
    border-radius: 4px;
    border-color: transparent;
    box-shadow: 0 2px 1px rgba(0,0,0,0.1);
    height: 2.5em;
    vertical-align: center;
    align-self: center;
}

.li-button-height-auto {
    height: auto;
    min-height: 2.5em;
}

.li-header-button {
    width: auto;
    height: 40px;
}

.li-primary {
    color: var(--li-white);
    background-color: var(--li-main-color);
}

.li-primary:active, .li-primary:hover {
    background-color: var(--li-main-color-active);
}

.li-primary:disabled, .li-primary:disabled:hover {
    background-color: var(--li-main-color-disabled);
    color: var(--li-muted);
}

.li-secondary {
    color: var(--li-white);
    background-color: var(--li-second-color);
}

.li-secondary:active, .li-secondary:hover {
    background-color: var(--li-second-color-active);
}

.li-secondary:disabled, .li-secondary:disabled:hover {
    background-color: var(--li-second-color-disabled);
    color: var(--li-muted);
}

.li-tertiary {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--li-white);
    background-color: var(--li-light-light-grey);
    border-color: transparent;
    padding: 0 5px;
    border-radius: 4px;
    box-shadow: 0 2px 1px rgba(0,0,0,0.1);
    height: 2em;
    vertical-align: center;
    align-self: center;
}

.li-tertiary:active, .li-tertiary:hover {
    background-color: var(--li-light-grey);
}

.li-tertiary:disabled, .li-tertiary:disabled:hover {
    background-color: var(--li-second-color-disabled);
    color: var(--li-muted);
}
/*#endregion BUTTONS */

/*#region POSITIONING */
.li-bottom-right {
    position: absolute;
    bottom: 0;
    right: 10px;
}

.li-bottom-left {
    position: absolute;
    bottom: 0;
    left: 10px;
}

.li-bottom {
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.li-top {
    position: absolute;
    top:10px;
}

.li-top-right {
    position: absolute;
    top:10px;
    right:10px;
}
/*#endregion POSITIONING */

/*#region LOGIN_FORM */

#loginForm input  {
    line-height: 1.2em;
    font-size: 1.2em;
}

#loginForm button {
    font-size: 16px !important;
}

/*#endregion LOGIN_FORM */

/*#region DASHBOARD_PAGE */
.li-dash-body-bg {
    background-color: var(--li-white);
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}
#dashLeftSection {
    /*margin-top: 80px;*/
}
#dashRightSection {
    padding-left: 30px;
}

#liOpBox {
    border-radius: 10px;
    background-color: var(--li-white);
    min-height: 100px;
}
#reloadBTN {
    border-radius: 10px;
    font-size: 16pt;
    vertical-align: center;
    align-self: center;
}

/*#endregion DASHBOARD_PAGE */

/*region MAG */

.li-mag-header {
    margin-left: 20px;
    margin-bottom: 5px;
    padding: 0;
    color: var(--li-white);
    width: 300px;
    /*height: 628px;*/
}

#magViewBacker {
    width: 300px;   /* 275x576 native */
    height: 628px;
    margin-left: 20px;
    background-color: var(--li-black);
    border-radius: 10px;
    color: var(--li-white);
    text-align: center;
    font-weight: bold;
    font-size: var(--li-large);
    border: white 3px solid;
}
#magViewNoMagLabel {
    padding-top: 170px;
}

.li-mag-image {
    align-self: center;
    width: 300px;   /* 275x576 native */
    /*margin-left: 20px;*/
    /*background-color: var(--li-black);*/
}
.li-mag-label {
    background-color: var(--li-black);
    border-radius:10px;
    border: 1px white solid;
    font-weight: bold;
    height: 50px;
}
.li-mag-light {
    background-color: transparent;
    border-radius: 10px;
    border: 1px dashed grey;
    height: 50px;
}

.li-full-button {
    cursor: pointer;
    font-weight: normal;
    font-size: var(--li-medium) !important;
    position: absolute;
    z-index: 1;
    background-color: var(--li-light-grey);
    padding: 5px 10px;
    border-radius: 0.5em;
    color: var(--li-muted);
}
.li-full-button.full {
    background-color: var(--li-danger);
    color: var(--li-black);
}
.li-full-button:disabled {
    border-color: black;
    color: black;
}

.li-slide-stack {
    position: absolute;
    line-height: 0;
}
.li-section-marker {
    position: absolute;
    color: var(--li-muted);
    font-weight: bold;
}
.li-numslides-marker {
    position: absolute;
    background-color: green;
    color: var(--li-black);
    font-weight: bold;
}

/*endregion*/

/*region SLIDE */ 
#slideViewBacker {
    border-radius: 10px;
    /*background-color: var(--li-black);*/
    /*color: var(--li-white);*/
    text-align: center;
    font-weight: bold;
    font-size: var(--li-large);
    border: black 3px solid;
    margin-left: auto;
    margin-right: auto;
    margin-top:10px;
}
#slideViewBacker.small {
    width: 155px;
    min-height:100px;
    /*height: 100px;*/
}
#slideViewBacker.normal {
    width: 300px;
    min-height:225px;
    /*height: 200px;*/
}
#slideViewBacker.medium {
    width: 100%;
    /*max-height: 400px;*/
    max-width: 400px;
}
#slideViewBacker.large {
    width: 100%;
    /*max-height: 400px;*/
    max-width: 600px;
}
#slideViewNoSlideLabel {
    padding-top: 70px;
    font-weight: normal;
    color: var(--li-muted);
}

.li-slide-image {
    width: 100%;
    background-color: var(--li-black);
    border-radius: 10px;
    border: solid black 6px;
}

.li-slide-label {
    background-color: var(--li-black);
    min-height: 2em;
    border-radius: 10px;
    border: 3px white solid;
    margin-bottom: 5px;
    padding: 0;
    color: var(--li-white);
    font-weight: bold;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
/*endregion*/

/*region SLIDE_ARROW*/
#slideArrow {
    position: absolute;
    width: 75px;
}

#slideArrow.bounce {
    animation: bounce .5s infinite alternate;
    -webkit-animation: bounce .5s infinite alternate;
}
@keyframes bounce {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(25px);
    }
}
@-webkit-keyframes bounce {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(25px);
    }
}
/*endregion */

/*region STATS TABLE */
#li-stats-label {
    color: white;
    font-size: var(--li-medium);
    font-weight: bold;
    background-color: var(--li-black);
    padding: 10px;
    width: 150px;
    border-radius: 10px 10px 0 0;
    margin-left: auto;
    margin-right: auto;
}
.li-stats-table-container {
    width: 150px;
    height: 70px;
    max-height: 70px;
    border-radius: 0 0 10px;
    margin-left: auto;
    margin-right: auto;
}

/*endregion*/

/*#region SLIDE_REPAIR_PAGE */
.li-sliderepair-body-bg {
    background-color:  white; /*#d49a59;*/
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#slideRepairTable {
    border: 2px solid transparent;
}

#profilesTr{
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.profileImageInList {
    max-height: 50px;
}
/*#endregion SLIDE_REPAIR_PAGE */

/*#region ADMIN_PAGE */
#statusTable td:first-of-type {
    text-align: right;
    font-weight: bold;
}

#commandTable td:first-of-type {
    text-align: right;
    font-weight: bold;
}

#adminTakePictureImage {
    margin-top: 0;
    min-width: 200px;
    max-width: 200px;
    border: solid black 1px;
}
#adminTakePictureDbImage {
    margin-top: 0;
    min-width: 200px;
    max-width: 200px;
    border: solid black 1px;
}

/*#endregion ADMIN_PAGE */

/*region POPUPS */
.modal-backdrop {
    width: 100% !important;
    height: 100% !important;
}
.li-modal-content {
    border-radius: 10px !important;
}
.li-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.modal-footer {
    flex-wrap: nowrap !important;
}
.modal-footer button {
    padding-left: 1em;
    padding-right: 1em;
    margin-right: 1em;
}
/*endregion*/

/*#region INTERESTING */
.li-flash {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    20% {
        opacity: 0;
    }
}
/*#endregion INTERESTING */

.li-pointer {
    cursor: pointer;
}
.li-pointer-default {
    cursor: default;
}

.li-centered-all {
    min-height: calc(90vh - 6em);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.li-centered-horiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.li-expected-list-card {
    padding: 10px;
    border-radius: 6px;
    height: 19em;
}

.li-power-button {
    position: absolute;
    top: 25px;
    right: 25px;
}

#loadingProcessUpdateBox {
    height: 95px;
}

.aside {
    display: block;
    margin: 5px 0;
    padding: 5px 10px;
    width: 100%;

    /* Abs positioning makes it not take up vert space */
    position: absolute;
    bottom: 0;
    left: 0;
    
    border-radius: 5px;

    /* Border is the new background */
    background: var(--li-main-color);
    color: var(--li-white);

    /* Rotate from top left corner (not default) */
    transform-origin: 21px 22px;
    transform: rotate(-90deg);
}



